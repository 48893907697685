<template>
    <section id="plan-tables">
        <div class="container">
            <div class="plan-subtitle" v-html="data?.subtitle"></div>
            <div class="plan-tables-title">
                    <h2 v-html="data?.title"></h2>
                <div class="plan-tables-title-text" v-html="data?.text"></div>
            </div>

            <RowTable id="account_status" :data="data?.tables.account_status"/>

            <div class="two-tables">
                <RowTable id="partner" :data="data?.tables.partner"/>
                <RowTable id="matching_bonus" :data="data?.tables.matching_bonus"/>
            </div>

            <RowTable id="leader_bonus" :data="data?.tables.leader_bonus"/>

            <div class="two-tables">
                <RowTable id="linear_bonus" :data="data?.tables.linear_bonus"/>
                <RowTable id="structure_bonus" class="desktop" :data="data?.tables.structure_bonus"/>
                <div class="table-swiper-container mobile">
                    <div :class="scroll_table > width_title ? 'visible' : ''" class="table-swiper-prev" @click="slidePrev">
                        <svg width="12.000000" height="7.000000" viewBox="0 0 12 7" fill="none" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
                            <path id="Arrow 43" d="M1.21 2.5L3.17 0.52C3.38 0.32 3.38 0.01 3.17 -0.19C2.98 -0.39 2.67 -0.39 2.47 -0.19L-0.36 2.64C-0.56 2.84 -0.56 3.15 -0.36 3.35L2.47 6.18C2.67 6.38 2.98 6.38 3.17 6.18C3.38 5.98 3.38 5.67 3.17 5.47L1.21 3.5L12 3.5L12 2.5L1.21 2.5Z" fill="#727272" fill-opacity="1.000000" fill-rule="evenodd"/>
                        </svg>
                        Мотайте влево
                    </div>
                    <div :class="scroll_table < max_scroll ? 'visible' : ''" class="table-swiper-next" @click="slideNext">
                        Мотайте вправо
                        <svg width="13.000000" height="7.000000" viewBox="0 0 13 7" fill="none" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
                            <path id="Arrow 43" d="M10.78 2.5L8.82 0.52C8.61 0.32 8.61 0.01 8.82 -0.19C9.01 -0.39 9.32 -0.39 9.52 -0.19L12.35 2.64C12.55 2.84 12.55 3.15 12.35 3.35L9.52 6.18C9.32 6.38 9.01 6.38 8.82 6.18C8.61 5.98 8.61 5.67 8.82 5.47L10.78 3.5L0 3.5L0 2.5L10.78 2.5Z" fill="#727272" fill-opacity="1.000000" fill-rule="evenodd"/>
                        </svg>
                    </div>
                    <div class="table-swiper" @scroll="setScroll" ref="table">
                        <div class="table-wrapper" ref="wrapper">
                            <RowTable id="structure_bonus" :data="data?.tables.structure_bonus"/>
                        </div>
                    </div>
                </div>
            </div>

            <div class="two-tables two-tables-reverse">
                <RowTable id="global_bonus" :data="data?.tables.global_bonus"/>
                <RowTable id="buisness_bonus" :data="data?.tables.buisness_bonus"/>
            </div>

        </div>
    </section>
</template>
<script>
import Swiper from 'swiper';
import SmoothScroll from 'smooth-scroll'
import "swiper/css";
import RowTable from '@/components/plan/RowTable.vue';
export default {
    props: {
        data: null,
    },
    data() {
        return {
            scroll_table: 0,
            max_scroll: 0,
            width_title: 0
        }
    },
    components: {
        RowTable
    },
    methods: {
        setScroll() {
            this.scroll_table = Math.abs(this.$refs.wrapper.getBoundingClientRect().x - 20)
        },
        slideNext() {
            if (this.scroll_table < this.$refs.wrapper.clientWidth) {
                this.$refs.table.scrollTo(this.$refs.wrapper.clientWidth + 100, 0);
                this.scroll_table = this.$refs.wrapper.clientWidth
            }
        },
        slidePrev() {
            if (this.scroll_table > 0) {
                this.$refs.table.scrollTo(0, 0);
                this.scroll_table = 0
            }
        },
        calculatScroll(){
            this.max_scroll = Math.abs( this.$refs.wrapper.clientWidth - this.$refs.wrapper.scrollWidth)
            this.width_title = this.$refs.wrapper.querySelector('.table-title span').clientWidth;

            setTimeout(() => {
                this.$refs.wrapper.querySelector('.table-title span').clientWidth
            }, 3000);
        }
    },
    mounted() {
        const interval = setInterval(() => {
            if (this.$refs.wrapper) {
                this.calculatScroll();
                clearInterval(interval);
            }
        }, 300);
        window.addEventListener('resize', ()=> {
            this.calculatScroll();
        })
    },
}
</script>
<style>
    #plan-tables .plan-subtitle {
        margin: 0px;
        margin-bottom: 15px;
        text-align: left;
    }

    .plan-tables-title {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        gap: 50px;
        margin-bottom: 64px
    }
    
    .plan-tables-title h2 {
        font-size: 42px;
        font-weight: 700;
        line-height: 116%;
        max-width: 603px;
    }

    .plan-tables-title-text {
        font-size: 14px;
        font-weight: 500;
        line-height: 164%;
        max-width: 547px;
    }

    .table-swiper {
        overflow-x: auto;
        padding-bottom: 20px;
        scroll-behavior: smooth;
    }

    .table-swiper::-webkit-scrollbar {
        width: 100%;
        height: 3px;
        border-radius: 38px;
        background: rgba(0, 0, 0, 0.12);
    }

    .table-swiper::-webkit-scrollbar-track {
        border-radius: 38px;
    }

    .table-swiper::-webkit-scrollbar-thumb {
        background-color: var(--blue);
        width: 40px;
        height: 3px;
    }

    .table-title span {
        display: block;
        width: max-content;
        max-width: 100%;
    }

    .two-tables {
        display: flex;
        flex-direction: row;
        gap: 12px;
    }

    .table {
        margin-bottom: 72px;
    }

    #account_status .table-head .table-head-item {
        font-size: 12px;
        font-weight: 600;
        line-height: 132%;
    }

    #account_status .table-head-item:first-of-type {
        background: var(--orange);
    }

    #account_status .table-row-item {
        font-size: 12px;
        font-weight: 600;
        line-height: 132%;
    }

    #partner {
        width: 100%;
    }

    #matching_bonus {
        width: 100%;
    }

    #leader_bonus .table-row-item:first-of-type {
        background: var(--biege);
    }

    #leader_bonus .table-head .table-head-item,
    #leader_bonus .table-row-item {
        font-weight: 600;
    }

    #leader_bonus .table-row-item {
        height: max-content;
    }

    #structure_bonus {
        width: 75%;
    }

    #linear_bonus {
        width: 25%;
    }

    #linear_bonus .table-head-item {
        font-weight: 600;
    }

    #structure_bonus .table-row-item:first-of-type {
        min-width: 143px;
        max-width: 143px;
        font-size: 9px;
        font-weight: 600;
        line-height: 132%;
    }  
    
    #structure_bonus .table-row-item:not(:first-of-type) {
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 18px;
        font-weight: 700;
    }

    #structure_bonus .table-head .table-head-item:first-of-type {
        min-width: 143px;
        max-width: 143px;
    }

    #structure_bonus .table-head .table-head-item {
        font-size: 12px;
        font-weight: 600;
        line-height: 132%;
    }

    #structure_bonus .table-row-item.none {
        opacity: 0;
    }

    #structure_bonus .table-head-item {
        font-size: 12px;
        font-weight: 600;
        line-height: 132%;
    }

    #global_bonus {
        width: 62.5%;
    }

    #global_bonus .table-head .table-head-item:first-of-type {
        min-width: 143px;
        max-width: 143px;
    }

    #global_bonus .table-row-item:first-of-type {
        min-width: 143px;
        max-width: 143px;
        font-size: 18px;
        font-weight: 700;
        line-height: 132%;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    #global_bonus .table-row-item {
        font-size: 10px;
        font-weight: 600;
        line-height: 132%;
    }

    #buisness_bonus {
        width: 37.5%;
    }

    #buisness_bonus .table-row-item:first-of-type {
        font-size: 12px;
        font-weight: 600;
        line-height: 132%;
    }

    #buisness_bonus .table-head .table-head-item:first-of-type {
        max-width: 143px;
        min-width: 143px;
    }

    #buisness_bonus .table-row-item:last-of-type {
        max-width: 143px;
        min-width: 143px;
        font-size: 18px;
        font-weight: 700;
        line-height: 132%;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    #buisness_bonus .table-head-item,
    #global_bonus .table-head-item {
        font-size: 12px;
        font-weight: 600;
        line-height: 132%;
    }

    #matching_bonus .table-head .table-head-item {
        font-weight: 600;
    }

    @media screen and (max-width: 1200px) {

        .table.desktop {
            display: none;
        }

        .plan-tables-title {
            flex-direction: column;
            gap: 16px;
            margin-bottom: 48px;
        }

        .plan-tables-title h2 {
            font-size: 24px;
        }

        .plan-tables-title-text {
            font-size: 12px;
        }

        .two-tables {
            flex-direction: column;
            gap: 0px
        }

        .table {
            margin-bottom: 48px;
        }

        #account_status .table-head,
        #account_status .table-row {
            flex-direction: column;
        }

        #account_status .table-head,
        #account_status .table-body {
            width: calc(50% - 4px)
        }
        #account_status {
            flex-direction: row;
            gap: 8px
        }

        #account_status .table-head-item:first-of-type {
            font-size: 12px;
            font-weight: 600;
            line-height: 132%;
        }

        #account_status .table-row-item:first-of-type {
            font-size: 11px;
        }

        #account_status .table-row-item {
            font-size: 12px;
        }

        #account_status .table-row-item:nth-child(2) {
            font-size: 10px;
            font-weight: 600;
            line-height: calc(132% + 4px);
        }

        #partner .table-row {
            display: grid;
            grid-template-columns: 1fr 1fr;
            column-gap: 8px;
            row-gap: 4px;
        }

        #partner .table-body {
            gap: 12px;
        }

        #partner .table-head-item.none {
            display: none;
        }

        #partner .table-row .table-row-item:first-of-type {
            grid-column: 1 / span 2;
        }

        #partner {
            display: flex;
            gap: 8px;
        }

        #partner .table-head .table-head-item {
            padding: 8px;
        }

        #partner .table-head {
            gap: 8px;
        }

        .table-head .table-head-item {
            font-size: 12px;
            font-weight: 800;
            line-height: 132%;
            padding: 8px 12px;
        }

        #matching_bonus .first-table {
            display: none
        }
        
        #matching_bonus .mobile-table {
            display: block;
        }

        #matching_bonus .mobile-table .table-body:last-of-type {
            margin-bottom: 0px;
        }

        #matching_bonus .table-head .table-head-item:first-of-type,
        #matching_bonus .table-row-item:first-of-type {
            min-width: 76px;
            max-width: 76px;
            margin-right: 5px;
        }

        #matching_bonus .table-head .table-head-item {
            width: 100%;
        }

        #matching_bonus .table-head-item.none {
            display: none;
        }

        #matching_bonus .table-head {
            gap: 3px;
            margin-bottom: 4px
        }

        #matching_bonus .table-body {
            margin-bottom: 12px;
        }

        #matching_bonus .table-row {
            gap: 3px;
        }

        #matching_bonus .table-row-item.none {
            display: none;
        }

        #matching_bonus .table-head-item.none-opacity,
        #matching_bonus .table-row-item.none-opacity {
            min-width: 76px;
            max-width: 76px;
            margin-right: 5px;
            opacity: 0;
        }

        #matching_bonus .table-head .table-head-item:first-of-type {
            opacity: 0;
        }

        #matching_bonus .table-head .table-head-item {
            font-size: 12px;
            font-weight: 600;
            line-height: 132%;
            text-transform: capitalize;
        }

        #matching_bonus .table-row-item:first-of-type {
            font-size: 10px;
            font-weight: 600;
            line-height: 132%;
            padding: 8px;
        }

        #matching_bonus {
            gap: 8px;
        }

        /* #leader_bonus .table-head,
        #leader_bonus .table-row {
            flex-direction: column;
        }
        
        #leader_bonus .table-head,
        #leader_bonus .table-body {
            width: calc(50% - 2px);
        }

        #leader_bonus .table-title {
            width: 100%;
        }

        #leader_bonus {
            flex-direction: row;
            flex-wrap: wrap;
        } */

        #leader_bonus {
            gap: 8px;
        }

        #leader_bonus .first-table {
            display: none;
        }

        #leader_bonus .grid-table {
            display: flex;
            flex-direction: column;
            gap: 4px;
        }

        #leader_bonus .table-row-item.none {
            display: none
        }

        #leader_bonus .table-row {
            display: grid;
            grid-template-columns: 1fr 1fr;
        }

        #leader_bonus  .table-head-item {
            font-size: 12px;
            font-weight: 600;
            line-height: 132%;
            text-transform: capitalize;
            border-radius: 4px;
            padding: 8px;
            background: var(--sky);
        }

        #linear_bonus,
        #global_bonus,
        #buisness_bonus {
            width: 100%
        }

        #global_bonus {
            margin-bottom: 0px;
        }

        .two-tables-reverse {
            flex-direction: column-reverse;
        }

        #buisness_bonus .table-head .table-head-item:first-of-type,
        #buisness_bonus .table-row-item:last-of-type {
            min-width: auto;
            max-width: 100%;
            width: 100%;
        }

        #buisness_bonus .table-row-item:first-of-type {
            font-size: 12px;
            padding: 12px;
        }

        #global_bonus .table-head .table-head-item:first-of-type,
        #global_bonus .table-row-item:first-of-type {
            max-width: 79px;
            min-width: 79px;
        }

        #structure_bonus {
            width: 100%;
            margin-bottom: 0px
        }

        .table-wrapper {
            position: relative;
            width: 100%;
        }

        .table-swiper-container {
            position: relative;
            margin-bottom: 45px;
        }

        .table-swiper-prev,
        .table-swiper-next {
            position: absolute;
            opacity: 0;
            transition: 0.5s;
            top: 8px;
            font-size: 12px;
            font-weight: 600;
            line-height: 132%;
            z-index: 10;
            color: var(--light_gray_text);
        }

        .table-swiper-prev.visible,
        .table-swiper-next.visible {
            opacity: 1;
        }

        .table-swiper-next {
            right: 20px;
        }

        .table-swiper-prev {
            left: 20px;
        }

        #plan-tables .plan-subtitle {
            margin-bottom: 3px;
        }

        .table-swiper {
            padding-bottom: 12px;
        }

        #leader_bonus .table-head-item {
            padding: 8px 12px;
        }

        #structure_bonus .table-row-item:not(:first-of-type) {
            padding: 8px
        }
    }

    @media screen and (max-width: 715px) {
        .table-wrapper {
            width: 100%;
        }
        #structure_bonus {
            width: max-content;
            transition: 0.8s
        }
    }
</style>