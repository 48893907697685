<template>
  <header>
    <div class="container">
      <a href="#">
        <img class="logo desktop" src="@/assets/img/logo.png" alt="" />
        <img class="logo mobile" src="@/assets/img/logo_m.png" alt="" />
      </a>
      <div class="nav-menu">
        <a
          :href="'/' + item?.link"
          class="nav-menu-item"
          v-for="(item, index) in data?.menu"
          >{{ item?.title }}</a
        >
      </div>
      <div class="avtoriz-language">
        <div ref="language" class="language" :class="is_mobile ? '' : 'hover-element'">
          <div @click="openLanguageMobile" v-if="this.$store.state.currentLang == 'uz_cyr'" class="language-btn">
              узб
          </div>
          <div v-else class="language-btn" @click="openLanguageMobile">
            {{ this.$store.state.currentLang }}
          </div>
          <div class="language-list-container" :class="language_open ? 'open' : ''">
            <div class="language-list">
              <a
                :href="item?.link"
                class="language-item"
                v-for="(item, index) in languages"
                ref="language"
                :class="
                  item?.tag == this.$store.state.currentLang ? 'active' : ''
                "
                @click="activeLanguage(item?.tag, index)"
                >{{ item?.text }}</a
              >
            </div>
          </div>
        </div>
        <!-- <a href="/autho" class="avtoriz desktop">
          {{ data?.btn_text }}
          <img src="@/assets/img/key.png" alt="" />
        </a> -->
        <a @click="openRedirect(data?.btn_link)" class="avtoriz desktop">
          {{ data?.btn_text }}
          <img src="@/assets/img/key.png" alt="" />
        </a>
        <div
          :class="
            'header_burger ' + ($store.state.mobileMenuClose ? 'active' : '')
          "
          @click="openMenu"
        >
          <span class="burger_top"></span>
          <span class="burger_middle"></span>
          <span class="burger_bottom"></span>
        </div>
      </div>
    </div>
  </header>
</template>
<script>
export default {
  props: {
    data: null,
    languages: null
  },
  data() {
    return {
      language: false,
      active_lang: "ru",
      language_open: false,
      is_mobile: null
    };
  },
  methods: {
    // openLanguage() {
    //   this.$store.commit("languagePopup", true);
    // },
    selectLanguage() {
      this.language = !this.language;
    },
    activeLanguage(tag, index) {
      this.active = index;
      this.language = false;
      this.$store.commit("setCurrentLang", tag);
    },
    // this.language = false
    openMenu() {
      this.$store.commit("closeMenu", true);
      this.$store.commit("openMenu", true);
    },
    openLanguageMobile() {
      if (window.innerWidth < 1201) {
        this.language_open = !this.language_open
      }
    },
    openRedirect(link) {
      this.$store.commit("openRedirect", true);
      this.$store.commit("setPopupLink", link);
    },
  },
  mounted() {
    window.addEventListener('click', (e) => {
      if (this.$refs.language && e.target) {
        if (!this.$refs.language.contains(e.target)) {
          this.language_open = false
        }
      }
    })
    if (localStorage.getItem("current_lang")) {
      this.active_lang = localStorage.getItem("current_lang");
    }
    if (window.innerWidth > 1200) {
      this.is_mobile = false
    } else {
      this.is_mobile = true
    }
  },
};
</script>
<style>
header {
  width: 100%;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 100;
  background-color: var(--white);
  border-bottom: 1px solid #e7e6ef;
  box-shadow: 0 0 10px 0 rgba(129, 129, 129, 0.2);
}

.logo {
  height: 53px;
}

header .container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.nav-menu {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 0px 32px;
  gap: 0px;
}

.nav-menu-item {
  display: block;
  font-family: Montserrat;
  padding: 32px 6px;
  color: #363636;
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  line-height: 108%;
  background: #fff;
  transition: 0.5s;
}

.nav-menu-item:hover {
  color: var(--blue);
  background: linear-gradient(
      0deg,
      rgba(10, 151, 160, 0.08) 0%,
      rgba(10, 151, 160, 0.08) 100%
    ),
    #fff;
}

.avtoriz-language {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 7px;
}

.avtoriz {
  position: relative;
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  justify-content: center;
  text-decoration: none;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  font-weight: 600;
  font-size: 14.4828px;
  line-height: 20px;
  text-align: center;
  border-radius: 12px;
  overflow: hidden;
  outline: none;
  -webkit-transition: 0.5s;
  transition: 0.5s;

  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 4px;
  padding-left: 44px;
  max-width: max-content;
  cursor: pointer;
  gap: 29px;
  /* background: linear-gradient(to left, #018d96 0%, #01adb7 100%); */
  color: var(--white);
  border-radius: 40px;
  font-family: Montserrat;
  font-size: 13px;
  font-style: normal;
  font-weight: 500;
  line-height: 108%;
  letter-spacing: -0.52px;
  transition: 0.5s;
}
.avtoriz::before {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(to left, #018d96 0%, #01adb7 100%);
  -webkit-transition: 0.5s;
  transition: 0.5s;
  border-radius: 40px;
  z-index: -1;
}

.avtoriz::after {
  pointer-events: none;
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(to left, #0a97a0d6 0%, #0a97a0d6 100%);
  opacity: 0;
  -webkit-transition: 0.5s;
  transition: 0.5s;
  border-radius: 40px;
  z-index: -1;
}

.avtoriz:hover::before,
.avtoriz:focus::before {
  opacity: 1;
  pointer-events: none;
}

.avtoriz:hover::after,
.avtoriz:focus::after {
  opacity: 1;
  pointer-events: none;
}

.language {
  position: relative;
}

.hover-element:hover .language-list-container {
  opacity: 1;
  pointer-events: all;
}

.language-btn {
  position: relative;
  color: var(--blue);
  font-family: Montserrat;
  display: flex;
  align-items: center;
  cursor: pointer;
  justify-content: center;
  font-size: 10px;
  font-style: normal;
  font-weight: 600;
  line-height: 132%;
  letter-spacing: 0.2px;
  text-transform: uppercase;
  padding: 12px 16px;
  border-radius: 40px;
  border: 1px solid var(--blue);
  background: var(--white);
  width: 54px;
  z-index: 100;
}

.language-list {
  padding: 16px;
  border-radius: 16px;
  background: #fff;
  box-shadow: 0px 8px 8px 0px rgba(0, 0, 0, 0.04);
  display: flex;
  flex-direction: column;
  gap: 12px;
  width: max-content;
}

.language-list-container {
  padding-top: 64px;
  opacity: 0;
  pointer-events: none;
  transition: 0.5s;
  position: absolute;
  top: 0px;
  left: 0px;
}

.language-list.active {
  opacity: 1;
  pointer-events: all;
}

.language-item {
  display: block;
  color: #363636;
  font-family: Montserrat;
  font-size: 10px;
  font-style: normal;
  font-weight: 600;
  line-height: 132%;
  letter-spacing: 0.2px;
  text-transform: uppercase;
  cursor: pointer;
  transition: 0.5s;
}

.language-item.active {
  color: var(--blue);
}
.header_burger {
  display: none;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  cursor: pointer;
  min-width: 24px;
  min-height: 16px;
  position: relative;
}

.header_burger.active {
  transition: 0.8s;
}

.header_burger span {
  position: absolute;
  width: 24px;
  height: 2px;
  background-color: var(--dark_gray);
  border-radius: 10px;
}

.burger_top {
  transform: translateY(-7px);
  transition: 0.8s;
}

.burger_middle {
  transition: 0.8s;
}

.burger_bottom {
  transform: translateY(7px);
  transform-origin: right;
  transition: 0.5s;
}
/* 
@media screen and (max-width: 1300px) {
  header {
    max-width: 1200px;
  }

  header.container {
    max-width: 1200px;
  }
} */

@media screen and (max-width: 1200px) {
  header .container {
    padding: 10px 20px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    border-bottom: 1px solid var(--sky);
  }

  .nav-menu {
    display: none;
  }

  .logo.mobile {
    width: 107px;
    height: 12px;
  }

  .header_burger {
    display: flex;
  }

  .mobile-menu.mobile {
    position: fixed;
    top: 0px;
    left: 0px;
    background: #0a97a0;
    padding-bottom: 48px;
    border-bottom-right-radius: 50% 2em;
    border-bottom-left-radius: 50% 2em;
    border-collapse: collapse;
  }

  .menu-list {
    columns: 2;
    width: 100%;
    list-style: none;
  }

  .menu-list li {
    color: var(--white);
  }

  .language-list-container.open {
    opacity: 1;
    pointer-events: all;
  }

  .language-list-container {
    left: auto;
    right: 0px;
    top: -20px;
  }
  
  .language-list {
    border: 1px solid var(--light_gray);
  }

  .avtoriz-language {
    gap: 15px;
  }
}
</style>
