export function sendComagic(name, phone, message) {
  if(typeof Comagic?.addOfflineRequest == "function"){
    Comagic.addOfflineRequest({
      name,
      phone,
      message
    }, function (o) { console.log(o); });
  }
}

export function sendToComagic(name, phone, message) {
  if(typeof Comagic?.addOfflineRequest == "function"){
    Comagic.addOfflineRequest({
      name,
      phone,
      message
    }, function (o) { console.log(o); });
  }
}