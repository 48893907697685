import { createStore } from "vuex";
import { pageModule } from "./pageModule";
import { appModule } from "./appModule";

export default createStore({
  state: () => {
    return {
      orderPopupShow: false,
      vacancyPopupShow: false,
      vacancyDesc: "",
      vacancyName: "",
      is_scroll: false,
      mobileMenuOpen: false,
      mobileMenuClose: false,
      successFormOrder: false,
      answerOpen: false,
      budgetError: false,
      rateError: false,
      mapOpen: false,
      examplePopup: false,
      supportPopupShow: false,
      openPopup: false,
      successPopup: false,
      // languagePopup: false,
      currentLang: 'ru',
      popupLink: '',
      currentPlace: '',
      openRedirect: false,
      popupmap: false,
      activeMarker: null,
      mentorPopup: false,
      mentorInfo: null,
      questionpopup: false
    };
  },
  getters: {},
  mutations: {
    examplePopup(state, bool) {
      state.examplePopup = bool;
    },
    setPopupMap(state, bool) {
      state.popupmap = bool;
    },
    setQuestionPopup(state, bool) {
      state.questionpopup = bool;
    },
    setActiveMarker(state, string) {
      state.activeMarker = string;
    },
    setScroll(state, bool) {
      state.is_scroll = bool;
    },
    openMenu(state, bool) {
      state.mobileMenuOpen = bool;
    },
    closeMenu(state, bool) {
      state.mobileMenuClose = bool;
    },
    successOrder(state, bool) {
      state.successFormOrder = bool;
    },
    answerOpen(state, bool) {
      state.answerOpen = bool;
    },
    budgetError(state, bool) {
      state.budgetError = bool;
    },
    rateError(state, bool) {
      state.rateError = bool;
    },
    mapOpen(state, bool) {
      state.mapOpen = bool;
    },
    openVacancy(state, bool) {
      state.vacancyPopupShow = bool;
    },
    setVacancyDesc(state, string) {
      state.vacancyDesc = string;
    },
    setVacancyName(state, string) {
      state.vacancyName = string;
    },
    openSupport(state, bool) {
      state.supportPopupShow = bool;
    },
    openPopup(state, bool) {
      state.openPopup = bool;
    },
    successPopup(state, bool) {
      state.successPopup = bool;
    },
    // languagePopup(state, bool) {
    //   state.languagePopup = bool;
    // },
    setCurrentLang(state, string) {
      state.currentLang = string;
    },
    setPopupLink(state, string) {
      state.popupLink = string;
    },
    openRedirect(state, bool) {
      state.openRedirect = bool;
    },
    currentPlace(state, string) {
      state.currentPlace = string;
    },
    mentorPopup(state, bool) {
      state.mentorPopup = bool;
    },
    mentorInfo(state, object) {
      state.mentorInfo = object;
    }
  },
  actions: {},
  modules: {
    page: pageModule,
    app: appModule,
  },
});
