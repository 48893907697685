<template>
    <section id="rangs" v-if="data != null">
        <div class="container">
            <div class="rangs-title">
                <svg width="70.000000" height="74.000000" viewBox="0 0 70 74" fill="none" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
                    <path id="Vector" d="M45.85 7.66L41.35 7.66C40.83 7.66 40.33 7.5 39.92 7.19C39.5 6.89 39.18 6.46 39.03 5.97L37.64 1.68C37.47 1.19 37.16 0.77 36.75 0.46C36.32 0.16 35.82 0 35.31 0C34.79 0 34.28 0.16 33.87 0.46C33.46 0.77 33.14 1.19 32.98 1.68L31.59 5.97C31.43 6.46 31.12 6.89 30.7 7.19C30.28 7.5 29.78 7.66 29.26 7.66L24.76 7.66C24.25 7.66 23.74 7.82 23.32 8.13C22.91 8.43 22.6 8.86 22.43 9.35C22.28 9.84 22.28 10.37 22.43 10.86C22.6 11.35 22.91 11.78 23.32 12.08L26.96 14.73C27.39 15.03 27.7 15.46 27.85 15.95C28.01 16.44 28.01 16.97 27.85 17.46L26.46 21.75C26.3 22.24 26.3 22.77 26.46 23.26C26.62 23.75 26.93 24.18 27.35 24.48C27.77 24.78 28.28 24.95 28.79 24.95C29.31 24.95 29.81 24.78 30.23 24.48L33.87 21.83C34.28 21.53 34.79 21.37 35.31 21.37C35.82 21.37 36.32 21.53 36.75 21.83L40.39 24.48C40.81 24.78 41.31 24.95 41.82 24.95C42.34 24.95 42.85 24.78 43.26 24.48C43.68 24.18 44 23.75 44.15 23.26C44.31 22.77 44.31 22.24 44.15 21.75L42.76 17.46C42.6 16.97 42.6 16.44 42.76 15.95C42.92 15.46 43.23 15.03 43.65 14.73L47.29 12.08C47.71 11.78 48.02 11.35 48.18 10.86C48.34 10.37 48.34 9.84 48.18 9.35C48.02 8.86 47.71 8.43 47.29 8.13C46.87 7.82 46.37 7.66 45.85 7.66Z" fill="#0A97A0" fill-opacity="1.000000" fill-rule="nonzero"/>
                    <path id="Vector" d="M28.72 31.52L41.27 31.52C42.1 31.52 42.89 31.85 43.47 32.44C44.06 33.02 44.39 33.82 44.39 34.64L44.39 70.79C44.39 71.61 44.06 72.41 43.48 72.99C42.89 73.58 42.1 73.91 41.27 73.91L28.73 73.91C27.9 73.91 27.1 73.58 26.52 72.99C25.93 72.41 25.6 71.61 25.6 70.79L25.6 34.64C25.6 33.82 25.93 33.02 26.52 32.44C27.1 31.85 27.89 31.52 28.72 31.52Z" fill="#0A97A0" fill-opacity="1.000000" fill-rule="nonzero"/>
                    <path id="Vector" d="M3.11 42.48L15.66 42.48C16.07 42.48 16.47 42.56 16.85 42.72C17.23 42.88 17.57 43.1 17.86 43.4C18.16 43.68 18.39 44.03 18.54 44.41C18.7 44.79 18.78 45.19 18.78 45.6L18.78 70.79C18.78 71.61 18.45 72.41 17.86 72.99C17.28 73.58 16.49 73.91 15.66 73.91L3.12 73.91C2.71 73.91 2.3 73.83 1.92 73.67C1.54 73.51 1.2 73.28 0.91 72.99C0.32 72.41 0 71.61 0 70.79L0 45.6C0 44.77 0.32 43.98 0.91 43.4C1.5 42.81 2.28 42.48 3.11 42.48Z" fill="#0A97A0" fill-opacity="1.000000" fill-rule="nonzero"/>
                    <path id="Vector" d="M54.32 42.48L66.87 42.48C67.7 42.48 68.5 42.81 69.07 43.4C69.66 43.98 69.99 44.77 69.99 45.6L69.99 70.79C69.99 71.61 69.66 72.41 69.07 72.99C68.5 73.58 67.7 73.91 66.87 73.91L54.33 73.91C53.5 73.91 52.71 73.58 52.12 72.99C51.53 72.41 51.21 71.61 51.21 70.79L51.21 45.6C51.21 44.77 51.53 43.98 52.12 43.4C52.71 42.81 53.5 42.48 54.32 42.48Z" fill="#0A97A0" fill-opacity="1.000000" fill-rule="nonzero"/>
                </svg>
                <span class="plan-subtitle" v-html="data?.subtitle"></span>
                <h2 v-html="data?.title"></h2>
            </div>
            <div class="rangs-list">
                <div class="rangs-item" v-for="item, index in data?.cards ">
                    <span class="number">0{{ index + 1 }}</span>
                    <p v-html="item?.text"></p>
                </div>
            </div>
        </div>
    </section>
</template>
<script>
export default {
    props: {
        data: null
    }
}
</script>
<style>
    .rangs-title {
        display: flex;
        flex-direction: column;
        align-items: center;
        margin-bottom: 57px;
    }

    .rangs-title svg {
        margin-bottom: 32px;
    }

    .rangs-title h2 {
        max-width: 603px;
        text-align: center;
    }

    .rangs-list {
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        gap: 20px;
    }

    .rangs-item {
        border-radius: 24px;
        padding: 24px;
        background: var(--re-light-green);
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        min-height: 200px;
        gap: 16px;
    }

    .rangs-item p {
        font-weight: 600;
    }

    .rangs-item span.number {
        font-size: 42px;
        font-weight: 600;
        line-height: 102%;
    }

    .rangs-item span {
        font-size: 24px;
        font-weight: 600;
        line-height: 102%;
    }

    @media screen and (max-width: 1200px) {
        #rangs .container {
            padding: 0px;
        }

        #plan #rangs {
            margin-bottom: 126px
        }

        .rangs-list {
            grid-template-columns: 1fr;
            gap: 8px;
        }

        .rangs-title {
            margin-bottom: 36px;
        }

        .rangs-item {
            min-height: 0px;
            gap: 12px;
        }

        .rangs-title svg {
            width: 49px;
            height: 52px;
            margin-bottom: 25px;
        }

        .rangs-item p {
            font-size: 13px;
            font-weight: 600;
        }

        .rangs-item span {
            font-size: 18px;
            font-weight: 600;
            line-height: 102%;
        }
    }
</style>