<template>
  <main>
    <MainSection             :data="data_k?.main"/>
    <Opportunities           :data="data_k?.opportunities"/>
    <MyWay                   :data="data_k?.way"/>
    <FirstAcquaintance       :data="data_k?.first_acquaintance"/>
    <WhyTao                  :data="data_k?.why_tao"/>
    <Quote                   :data="data_k?.quote"/>
    <Achievements            :data="data_k?.achievement"/>
    <History                 :data="data_k?.history"/>
    <QuestionWS              :data="data?.question_ws"/>
    <Invites                 :data="data?.invites"/>
    <PlusesMarketing         :data="data?.pluses" />
    <Together                :data="data?.together"/>
    <BusinessModel           :data="data?.business_model"/>
    <Helping                 :data="data?.helping"/>
    <ManufacturingCompany    :data="data?.manufacturing_company" />
    <QuestionWS              :data="data?.question_ws"/>
    <Largest                 :data="data?.largest"/>
    <ChineseMedicine         :data="data?.medicine"/>
    <Friends                 :data="data?.friends"/>
    <StartWs                 :data="data?.start_ws "/>
    <Recipe                  :data="data?.recipe "/>
    <Catalog v-if="this.currentLang == 'ru'" :data="data?.catalog"/>
    <QuestionWS              :data="data?.question_ws"/>
    <Bonuses                 :data="data?.bonuses" />
    <Marketing               :data="data?.marketing" />
    <Healthy                 :data="data?.healthy" />
    <InterestBusiness        :data="data?.interest_business" />
    <Easy                    :data="data?.easy" />
    <QuestionWS              :data="data?.question_ws"/>
    <Mentor                  :data="data?.mentor" />
    <PartnerReviews          :data="data?.reviews" />
    <ReviewsSliderProduction :data="data?.production" />
    <Questions               :data="data?.questions" />
    <TaobitaOrder ref="order" :data="data?.form" />
  </main>
</template>
<script>
import router from "@/router";
import axios from "axios";
import { mapMutations, mapState } from "vuex";
import MyWay from "@/components/home/MyWay.vue";
import Achievements from "@/components/home/Achievements.vue";
import Quote from "@/components/home/Quote.vue";
import MainSection from "@/components/home/Main.vue";
import Largest from "@/components/home/Largest.vue";
import Helping from "@/components/home/Helping.vue";
import BusinessModel from "@/components/home/BusinessModel.vue";
import ChineseMedicine from "@/components/home/ChineseMedicine.vue";
import Recipe from "@/components/home/Recipe.vue";
import Bonuses from "@/components/home/Bonuses.vue";
import Marketing from "@/components/home/Marketing.vue";
import Healthy from "@/components/home/Healthy.vue";
import InterestBusiness from "@/components/home/InterestBusiness.vue";
import FutherPath from "@/components/home/FurtherPath.vue";
import History from "@/components/home/History.vue";
import Easy from "@/components/home/Easy.vue";
import Start from "@/components/home/Start.vue";
import Coronavirus from "@/components/home/Coronavirus.vue";
import Questions from "@/components/home/Questions.vue";
import TaobitaOrder from "@/components/home/TaobitaOrder.vue";
import Invites from "@/components/home/Invites.vue";
import Together from "@/components/home/Together.vue";
import ManufacturingCompany from "@/components/home/ManufacturingCompany.vue";
import PlusesMarketing from "@/components/home/PlusesMarketing.vue";
import ReviewsSliderProduction from "@/components/home/ReviewsSliderProduction.vue";
import PartnerReviews from "@/components/home/PartnerReviews.vue";
import Mentor from "@/components/home/Mentor.vue";
import Header from "@/components/UI/Header.vue";
import Footer from "@/components/Footer.vue";
// import LanguagePopup from "@/components/UI/LanguagePopup.vue";
import QuestionWS from "@/components/home/QuestionWS.vue";
import StartWs from "@/components/home/StartWs.vue";
import Friends from "@/components/home/Friends.vue";
import Catalog from "@/components/home/Catalog.vue";
import Opportunities from "@/components/home/Opportunities.vue";
import FirstAcquaintance from "@/components/home/FirstAcquaintance.vue";
import WhyTao from "@/components/home/WhyTao.vue";

import SmoothScroll from 'smooth-scroll'

export default {
  name: "HomeView",
  components: {
    Together,
    MainSection,
    Largest,
    Helping,
    BusinessModel,
    ChineseMedicine,
    Recipe,
    Bonuses,
    Marketing,
    Healthy,
    Mentor,
    InterestBusiness,
    Easy,
    Start,
    Coronavirus,
    Questions,
    TaobitaOrder,
    FutherPath,
    History,
    Invites,
    ManufacturingCompany,
    PlusesMarketing,
    ReviewsSliderProduction,
    PartnerReviews,
    Header,
    Footer,
    // LanguagePopup,
    QuestionWS,
    StartWs,
    Friends,
    Catalog,
    Opportunities,
    FirstAcquaintance,
    WhyTao,
    MyWay,
    Quote,
    Achievements,
  },
  data() {
    return {
      data: null,
      data_k: null,
    };
  },
  methods: {
    ...mapMutations({
      setLoading: "page/setLoading",
    }),
    async getData() { 
      try { 
        const result = await axios.get( 
          "https://mayaydyryssova.com/v1/methods/home" 
        );   
        console.log(this.data); 
        this.data = result.data; 
        setTimeout(() => { 
          this.setLoading(false); 
          const href = router.currentRoute.value.href 
          if (href) { 
            if (href.indexOf('#') != -1) { 
              window.location.href = href 
            } 
          } 
        }, 2000); 
      } catch (error) { 
        router.push("/404"); 
        console.clear(); 
        console.error(error); 
      } 
    }, 
    async getDatas() {
      try {
        const result_k = await axios.get(
          "https://kulyash.com/v1/methods/home/?ru"
        );
        this.data_k = result_k.data;
        console.log(this.data_k);
        setTimeout(() => {
          this.setLoading(false);
          const href = router.currentRoute.value.href
          if (href) {
            if (href.indexOf('#') != -1) {
              window.location.href = href
            }
          }
        }, 2000);
        
      } catch (error) {
        router.push("/404");
        console.clear();
        console.error(error);
      }
    },
  },
  computed: {
      ...mapState({
          currentLang: state => state.currentLang,
      })
  },
  watch: {
    currentLang() {
      setTimeout(() => {
        this.getData();
        this.getDatas();
      }, 500);
    },
  },
  mounted() {
    const scroll = new SmoothScroll('a[href*="#"]', {
      offset: 108,
      speed: 200 
    });
    this.getData();
    this.getDatas();
  }  
};

</script>
<style></style>
