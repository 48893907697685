<template>
    <section id="main-plan" v-if="data">
        <div class="container">
            <div class="plan-main-text">
                <span class="plan-subtitle" v-html="data?.subtitle"></span>
                <h1 v-html="data?.title"></h1>
                <p v-html="data?.text"></p>
            </div>  
            <div class="plan-main-cards">
                <div class="plan-main-card" v-for="item in data?.numbers">
                    <span>{{ item.number }}</span>
                    <p v-html="item?.text"></p>
                </div>
            </div>
        </div>
    </section>
</template>
<script>
export default {
    props: {
        data: null
    }
}
</script>
<style>
    #main-plan {
        background: url('../../assets/img/plan-main.jpg');
        margin-top: 80px;
        padding-top: 108px;
        padding-bottom: 57px;
        margin-bottom: 160px;
    }

    .plan-main-text {
        display: flex;
        flex-direction: column;
        align-items: center;
        margin-bottom: 112px;
    }

    .plan-main-text h1 {
        font-size: 48px;
        font-weight: 700;
        line-height: 102%;
        letter-spacing: 0.02px;
        margin-bottom: 24px;
        max-width: 628px;
        text-align: center;
    }

    .plan-main-text p {
        font-size: 18px;
        font-weight: 600;
        line-height: 164%;
        text-align: center;
        max-width: 444px;
    }

    .plan-main-cards {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
    }

    .plan-main-card {
        display: flex;
        flex-direction: column;
        gap: 2px;
    }

    .plan-main-card span {
        color: var(--blue);
        font-size: 36px;
        font-weight: 700;
        line-height: 132%;
    }

    .plan-main-card p {
        font-size: 16px;
        font-weight: 600;
        line-height: 142%;
        max-width: 191px;
    }

    @media screen and (max-width: 1200px) {
        #plan #main-plan {
            background-size: cover;
            margin-top: 61px;
            padding-top: 155px;
            padding-bottom: 40px;
            margin-bottom: 123px;
        }

        .plan-main-text h1 {
            font-size: 32px;
            font-weight: 700;
            line-height: 108%;
        }

        .plan-main-text p {
            font-size: 13px;
            font-weight: 600;
            line-height: 164%;
        }

        .plan-main-cards {
            display: grid;
            grid-template-columns: 1fr 1fr;
            column-gap: 8px;
            row-gap: 31px;
        }

        .plan-main-text {
            margin-bottom: 132px;
        }

        .plan-main-card span {
            font-size: 24px;
        }

        .plan-main-card p {
            font-size: 13px;
        }
    }
</style>