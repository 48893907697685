<template>
    <main>
        <section id="registrate">
            <div class="container">
                <div class="reg-container">
                    <div class="reg-left">
                        <div class="logo-text">
                            <img
                                :src="require('@/assets/img/logo_m.png')"
                                alt=""
                            />
                            <h1 v-html="data?.reg.title"></h1>
                        </div>
                        <div class="reg-left-lk pc" v-html="data?.reg.text_link"></div>
                    </div>
                    <form action="" class="reg-right" ref="registr">
                        <div class="input-item">
                            <input
                                type="text"
                                name=""
                                id=""
                                :placeholder="data?.reg.name_p"
                                v-model="name"
                                :class="error_name == '' ? '' : 'reg-error'"
                            />
                            <span
                                :class="error_name == '' ? '' : 'reg-error'"
                                >{{ error_name }}</span
                            >
                        </div>
                        <div class="input-item">
                            <input
                                type="text"
                                name=""
                                id=""
                                :placeholder="data?.reg.email_p"
                                v-model="email"
                                :class="error_email == '' ? '' : 'reg-error'"
                            />
                            <span
                                :class="error_email == '' ? '' : 'reg-error'"
                                >{{ error_email }}</span
                            >
                        </div>
                        <div class="input-item">
                            <input
                                type="password"
                                name=""
                                id=""
                                :placeholder="data?.reg.password_p"
                                v-model="password"
                                :class="error_password == '' ? '' : 'reg-error'"
                            />
                            <span
                                :class="error_password == '' ? '' : 'reg-error'"
                                >{{ error_password }}</span
                            >
                        </div>
                        <div class="input-item">
                            <div class="phone-input">
                                <span :class="phone != '' ? 'block' : ''" class="code" v-html="code"></span>
                                <input
                                    type="text"
                                    name=""
                                    id=""
                                    ref="phone"
                                    :placeholder="data?.reg.phone_p"
                                    v-model="phone"
                                    :class="error_phone == '' ? '' : 'reg-error'"
                                />
                            </div>
                            <span
                                :class="error_phone == '' ? '' : 'reg-error'"
                                >{{ error_phone }}</span
                            >
                        </div>
                        <div class="input-item">
                            <input
                                type="password"
                                name=""
                                id=""
                                :placeholder="data?.reg.accept_p"
                                v-model="password_repeat"
                                :class="
                                    error_password_repeat == ''
                                        ? ''
                                        : 'reg-error'
                                "
                            />
                            <span
                                :class="
                                    error_password_repeat == ''
                                        ? ''
                                        : 'reg-error'
                                "
                                >{{ error_password_repeat }}</span
                            >
                        </div>
                        <div
                            class="select-mentor"
                            @click="openMentor"
                            v-if="!mentor"
                            v-html="data?.reg.mentor"
                        >
                        </div>
                        <div
                            class="select-mentor current"
                            @click="openMentor"
                            v-else
                        >
                            <img :src="mentor.img" alt="" />
                            <div class="mentor-item-name">
                                {{ mentor.name }}
                            </div>
                        </div>
                        <div class="btn-reg-container">
                            <Button
                                class="btn-reg"
                                :btn_class="'blue'"
                                :value="data?.reg.btn_text"
                                @click="createOrder"
                            />
                            <div class="police" v-html="data?.reg.police">
                            </div>
                        </div>
                        <div class="reg-left-lk mob" v-html="data?.reg.text_link">
                        </div>
                    </form>
                </div>
            </div>
        </section>
    </main>
</template>
<script>
import { mapMutations, mapState } from "vuex";
import Button from "@/components/UI/Button.vue";
import {
    replaceNumberForPaste,
    replaceToLatters,
    validateEmail,
} from "@/assets/js/helper.js";
import axios from "axios";
import { sendComagic } from "@/assets/js/comagic.js";
import regApi from "@/assets/js/api/registration/registration.js";
import md5 from "md5";
import countries from "@/assets/js/countries.js";
import inputmask from "@/assets/js/libs/inputmask.js";
export default {
    props: {
        data: null
    },
    components: {
        Button,
    },
    data() {
        return {
            name: "",
            phone: "",
            email: "",
            password: "",
            password_repeat: "",
            error_name: "",
            error_phone: "",
            error_email: "",
            error_password: "",
            error_password_repeat: "",

            is_validated: false,
            error_order: "",
            countries: [],
            default: {},
            code: null
        };
    },
    methods: {
        openMentor() {
            this.$store.commit("mentorPopup", true);
        },
        async avtoriz() {
            let form_data = new FormData();
            let data_a = {
                client: "taovita-mobile",
                method: "client/visitor/authorize",
                email: this.email,
                passwd: this.password,
            };

            let config_a = {
                method: "post",
                maxBodyLength: Infinity,
                url: "https://mayaydyryssova.com/v1/methods/taovita_auth",
                data: form_data,
            };

            form_data.append(
                "url",
                "https://taovita.ru/do.rapi/request.json"
            );
            form_data.append("data", JSON.stringify(data_a));
            axios
            .request(config_a)
            .then((response) => {
                if (response.data?.message == "ok") {
                    window.location =
                        "https://taovita.ru/do.rapi/auth?token=" +
                        response.data?.token +
                        "&done=https://taovita.ru/office/dashboard";
                }
            })
            .catch((error) => {
                console.log(error);
            });
        },
        async createOrder() {
            this.error_order = "";
            if (this.validate()) {
                let form_data = new FormData();
                let data = {
                    client: "taovita",
                    email: this.email,
                    firstname: this.name,
                    method: "client/visitor/create",
                    passwd: this.password,
                    phone: this.code + this.phone,
                };
                form_data.append(
                    "url",
                    "https://taovita.ru/do.rapi/request.json"
                );
                form_data.append("data", JSON.stringify(data));

                let config = {
                    method: "post",
                    maxBodyLength: Infinity,
                    url: "https://mayaydyryssova.com/v1/methods/taovita",
                    data: form_data,
                };
                axios
                    .request(config)
                    .then((response) => {
                        if (response?.data?.message == "ok") {
                            this.avtoriz();
                            this.name = "";
                            this.phone = "";
                            this.email = "";
                            this.password = "";
                            this.password_repeat = "";
                            this.error_name = "";
                            this.error_phone = "";
                            this.error_email = "";
                            this.error_password = "";
                            this.error_password_repeat = "";
                            this.is_validated = false;
                            this.error_order = "";
                            this.$store.commit("mentorInfo", null);
                        } else {
                            console.log("u have some problem");
                        }
                    })
                    .catch((error) => {
                        console.log(error);
                    });
            }
        },
        validate() {
            let req_field = "";
            let phone_field = "";
            let email_field = "";
            let password_field = "";
            let password_repeat_field = "";
            if (this.$store.state.currentLang == "ru") {
                req_field = "Обязательное поле";
                phone_field = "Введите существующий номер";
                email_field = "Введите существующую почту";
                password_field = "Пароль должен быть более 6ти символов";
                password_repeat_field = "Пароли должны совпадать";
            }

            if (this.$store.state.currentLang == "kz") {
              req_field = "Міндетті өріс";
              phone_field = "Бар нөмірді енгізіңіз";
              email_field = "Введите существующую почту";
                password_field = "Пароль должен быть более 6ти символов";
                password_repeat_field = "Пароли должны совпадать";
            }

            if (this.$store.state.currentLang == "uz") {
              req_field = "Majburiy maydon";
              phone_field = "Mavjud raqamni kiriting";
              email_field = "Введите существующую почту";
                password_field = "Пароль должен быть более 6ти символов";
                password_repeat_field = "Пароли должны совпадать";
            }

            if (this.$store.state.currentLang == "uz_cyr") {
              req_field = "Керакли майдон";
              phone_field = "Мавжуд рақамни киритинг";
              email_field = "Введите существующую почту";
                password_field = "Пароль должен быть более 6ти символов";
                password_repeat_field = "Пароли должны совпадать";
            }

            if (this.$store.state.currentLang == "kg") {
              req_field = "Милдеттүү талаа";
              phone_field = "Учурдагы Номерди киргизиңиз";
              email_field = "Введите существующую почту";
                password_field = "Пароль должен быть более 6ти символов";
                password_repeat_field = "Пароли должны совпадать";
            }

            if (this.$store.state.currentLang == "en") {
              req_field = "Required field";
              phone_field = "Enter an existing numbe";
              email_field = "Введите существующую почту";
                password_field = "Пароль должен быть более 6ти символов";
                password_repeat_field = "Пароли должны совпадать";
            }

            if (this.$store.state.currentLang == "tj") {
              req_field = "Майдони ҳатмӣ";
              phone_field = "Раками дохил кунед";
              email_field = "Введите существующую почту";
                password_field = "Пароль должен быть более 6ти символов";
                password_repeat_field = "Пароли должны совпадать";
            }

            this.error_name = "";
            this.error_phone = "";
            this.error_email = "";
            this.error_password = "";
            this.error_password_repeat = "";

            if (this.name == "") {
                this.error_name = req_field;
            }

            if (this.password == "") {
                this.error_password = req_field;
            } else if (this.password.length < 6) {
                this.error_password = password_field;
            } else if (this.password !== this.password_repeat) {
                this.error_password = password_repeat_field;
            }

            if (this.password_repeat == "") {
                this.error_password_repeat = req_field;
            } else if (this.password.length < 6) {
                this.error_password_repeat = password_field;
            } else if (this.password !== this.password_repeat) {
                this.error_password_repeat = password_repeat_field;
            }

            if (this.phone == "") {
                this.error_phone = req_field;
            } else {
                if (this.$store.state.currentLang == 'ru' || this.$store.state.currentLang == 'kz') {
                    if (this.phone.replaceAll("-", '').replace("(", '').replace(")", '').replaceAll("_", '').length < 10) {
                        this.error_phone = phone_field;
                    }
                } else {
                    if (this.phone.replaceAll("-", '').replace("(", '').replace(")", '').replaceAll("_", '').length < 9) {
                        this.error_phone = phone_field;
                    }
                }
            }

            if (this.email == "") {
                this.error_email = req_field;
            } else if (!validateEmail(this.email)) {
                this.error_email = email_field;
                return;
            } else {
                this.error_email = "";
            }

            this.is_validated = true;

            return this.error_name == "" && this.error_phone == "";
        },
        ...mapMutations({
            setLoading: "page/setLoading",
        }),
    },
    mounted() {
        const inp = this.$refs.phone
        countries.forEach(item => {
            if (item.iso == this.$store.state.currentLang.toUpperCase()) {
                this.code = item.code
                this.default = item
                inputmask(item).mask(inp);
            }
        });
    },
    watch: {
        currentLang(new_value) {
            this.error_name = "";
            this.error_phone = "";
            const inp = this.$refs.phone
            countries.forEach(item => {
                if (item.iso == new_value.toUpperCase()) {
                    this.code = item.code;
                    inputmask(item).mask(inp);
                }
            });
        },
        name(new_value) {
            this.name = replaceToLatters(new_value);
            if (this.is_validated) {
                this.validate();
            }
        },
        phone(new_value) {
            // this.phone = replaceNumberForPaste(new_value, this.currentLang);
            if (this.is_validated) {
                this.validate(); 
            }
        },
        email(new_value) {
            this.email = new_value;
            if (this.is_validated) {
                this.validate();
            }
        },
        password(new_value) {
            this.password = new_value;
            if (this.is_validated) {
                this.validate();
            }
        },
        password_repeat(new_value) {
            this.password_repeat = new_value;
            if (this.is_validated) {
                this.validate();
            }
        },
    },
    computed: {
        ...mapState({
            currentLang: (state) => state.currentLang,
        }),
        mentor() {
            return this.$store.state.mentorInfo;
        },
    },
};
</script>
<style>
#registrate {
    padding: 170px 0px 164px;
    background-color: #c2e8e1;
    margin: 0;
}

.reg-container {
    max-width: 1080px;
}

.reg-left {
    min-width: max-content;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.reg-left h1 {
    font-size: 36px;
    font-weight: 700;
    line-height: 124%;
}

.reg-left-lk {
    color: rgba(54, 54, 54, 0.64);
    font-family: Montserrat;
    font-size: 14px;
    font-weight: 600;
    line-height: 18px;
    letter-spacing: 0.02em;
    text-align: left;
}
.reg-left-lk a {
    color: var(--blue);
    text-decoration: underline;
    font-weight: 600;
    transition: 0.5s;
}
.reg-left-lk a:hover {
    color: rgba(10, 151, 160, 0.84);
}
.reg-left-lk.mob {
    display: none;
}
.reg-container {
    display: flex;
    flex-direction: row;
    gap: 75px;
    background-color: var(--white);
    border-radius: 32px;
    padding: 48px;
}

.reg-right {
    width: 100%;
    display: grid;
    grid-template-columns: 1fr 1fr;
    column-gap: 12px;
    row-gap: 32px;
}
.input-item {
    position: relative;
}

/* .input-item .code {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    left: 16px;
} */

.input-item input,
.input-item .phone-input {
    box-sizing: border-box;
    border: 1px solid rgba(54, 54, 54, 0.24);
    border-radius: 12px;
    background: rgba(54, 54, 54, 0.08);
    font-size: 12px;
    font-weight: 600;
    line-height: 132%;
    text-transform: uppercase;
    padding: 16px;
    width: 100%;
    transition: 0.5s;
}

.phone-input {
    display: flex;
    flex-direction: row;
}

.input-item .phone-input input {
    border: none;
    border-radius: none;
    background: transparent;
    padding: 0px;
    width: max-content;
}

.input-item .phone-input .code {
    position: relative;
    width: max-content;
    display: none;
    padding-right: 4px;
    color: var(--gray);
    font-size: 12px;
}

.phone-input span.code.block {
    display: block;
}

.input-item .phone-input:has(input:hover) .code,
.input-item .phone-input:has(input:focus) .code {
    display: block;
}

.input-item input::placeholder {
    font-size: 12px;
    font-weight: 600;
    line-height: 132%;
}

.input-item input:focus {
    border-color: #363636;
    outline: #363636;
    color: #363636;
}
.input-item input:not(:placeholder-shown) {
    border-color: #363636;
    outline: #363636;
}

.input-item input.reg-error {
    border-color: var(--errors);
}

.input-item span {
    position: absolute;
    font-size: 11px;
    width: 100%;
    top: calc(100% + 4px);
    left: 0;
    transition: 0.5s;
    color: var(--errors);
}

.input-item span.reg-error {
    font-family: Montserrat;
    font-weight: 500;
    line-height: 132%;
}

.select-mentor {
    box-sizing: border-box;
    border: 1px solid rgba(54, 54, 54, 0.24);
    border-radius: 12px;
    padding: 16px;
    color: var(--blue);
    font-size: 12px;
    font-weight: 600;
    line-height: 132%;
    cursor: pointer;
    text-transform: uppercase;
}
.select-mentor.current {
    display: flex;
    padding: 6px;
    gap: 12px;
    align-items: center;
}

.select-mentor.current img {
    width: 36px;
    aspect-ratio: 1;
    border-radius: 6px;
}
.select-mentor.current .mentor-item-name {
    color: #363636;
    font-weight: 700;
    font-size: 12px;
    line-height: 132%;
    letter-spacing: 0.02px;
}
.btn-reg {
    color: var(--white);
    padding: 16px 24px;
    font-size: 14px;
    font-weight: 700;
    line-height: 132%;
    border-radius: 12px;
    background: rgb(10, 151, 160);
    text-align: center;
    margin-bottom: 8px;
    text-transform: uppercase;
    width: auto;
}

.police {
    color: rgba(54, 54, 54, 0.64);
    font-size: 8px;
    font-weight: 400;
    line-height: 136%;
}

.police a {
    color: rgba(54, 54, 54, 0.64);
    font-family: Montserrat;
    font-size: 8px;
    font-weight: 600;
    line-height: 11px;
    letter-spacing: 0.02em;
    text-align: left;
    text-decoration: underline;
}

@media screen and (max-width: 1200px) {
    #registrate {
        padding: 124px 0 100px;
    }
    .reg-container {
        max-width: none;
        flex-direction: column;
        gap: 24px;
        padding: 40px 24px;
    }
    .reg-left-lk.pc {
        display: none;
    }
    .reg-right {
        grid-template-columns: 1fr;
    }
    .input-item:nth-child(4) {
        grid-area: 3 / 1 / 4 / 2;
    }
    .reg-left-lk.mob {
        display: block;
    }
    .reg-left h1 {
        font-weight: 700;
        font-size: 28px;
        line-height: 124%;
    }
    .police,
    .police a {
        font-size: 10px;
    }
    .btn-reg {
        font-size: 12px;
    }

    .reg-left {
        width: 100%;
    }
}
</style>
