<template>
    <Form url = 'https://kulyash.com/v1/methods/createOrder' method = 'POST'>
        <input name="name" type="text" placeholder="Имя">
        <input name="phone" type="text" placeholder="Телефон">
        <button>Отправить</button>
    </Form>
</template>
<script>
import Form from '@/components/base/Form.vue';

export default {
    components: {
        Form,
    },
}
</script>
<style>

</style>