<template>
    <section id="achievement">
        <div class="container">
            <div class="achievement">
                <img class="achievement-img" :src="data?.img">
                <div class="achievement-info">
                    <div class="top-span" v-html="data?.top_title"></div>
                    <h2 v-html="data?.title"></h2>
                    <p class="achievement-info-text" v-html="data?.text"></p>
                    <div class="achievement-info-quantity" > 
                        <div class="achievement-info-quantity-column" v-for="item in data?.column">
                            <p class="achievement-info-quantity-column-number">{{item?.number}}</p>
                            <p class="achievement-info-quantity-column-text">{{item?.content}}</p>
                        </div>
                    </div>
                    <p class="achievement-info-desc" v-html="data?.desc"></p>
                    <Button @click="popupOpen" class="achievement-btn" btn_class="blue" :value="data?.btn"></Button>
                </div>
            </div>
        </div>    
    </section>
</template>

<script>
import Button from "../UI/Button.vue";

export default {
    components: {
        Button,
    },
    props: {
        data: null,
    },
    methods: {
        popupOpen() {
            this.$store.commit('currentPlace', 'Форма “Приглашаем амбициозных и уверенных”');
            this.$store.commit("openPopup", true);
        },
        openRedirect() {
            this.$store.commit("openRedirect", true);
            this.$store.commit("setPopupLink", this.data?.items[1].link);
        },
    },
};
</script>

<style>
.achievement {
    display: flex;
    gap: 40px;
}

#achievement .top-span{
    margin-bottom: 14px;
}

.achievement-img {
    width: 100%;
    height: 100%;
    max-width: 580px;
    max-height: 573.6px;
    aspect-ratio: 1;
    border-radius: 50%;
}

.achievement-info-text {
    font-family: Montserrat;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 164%;
    text-align: left;
    margin: 48px 0 24px;
}

.achievement-info-quantity {
    display: flex;
    gap: 27px;

}

.achievement-info-quantity-column-number {
    font-family: Montserrat;
    font-size: 36px;
    font-weight: 500;
    line-height: 47.52px;
    text-align: left;
    padding: 0 0 8px 0;
    border-bottom: 1px solid #C2E8E1;
}

.achievement-info-quantity-column-text {
    font-family: Montserrat;
    font-size: 16px;
    font-weight: 600;
    line-height: 22.72px;
    padding: 8px 0 0  0;
    text-align: left;
}

.achievement-info-desc {
    font-family: Montserrat;
    font-size: 18px;
    font-weight: 600;
    line-height: 29.52px;
    text-align: left;
    margin: 32px 0 48px;
}

.button.achievement-btn {
    background-color: #0A97A0;
    color: #fff;
    font-size: 14px;
    border-radius: 16px;
    /* width: 270px; */
    padding: 16px 58px;
    text-transform: none;
}

@media screen and (max-width: 1200px) {
    .achievement {
        flex-direction: column-reverse;
        gap: 36px;
    }

    .achievement-info-text {
        font-size: 12px;
        line-height: 19.68px;
        margin: 36px 0 16px;
    }

    .achievement-info-quantity {
        gap: 12px;
    }

    .achievement-info-quantity-column-number {
        font-size: 24px;
        line-height: 31.68px;
        padding: 0 0 4px 0;
    }

    .achievement-info-quantity-column-text {
        padding: 4px 0 0 0;
        font-size: 13px;
        font-weight: 500;
        line-height: 19.24px;
    }

    .achievement-info-desc {
        font-size: 14px;
        line-height: 22.96px;
        margin: 24px 0 42px;
        
    }
    .button.achievement-btn {
        width: 100%;
        font-size: 14px;
        font-weight: 700;
        line-height: 18.48px;
        letter-spacing: 0.02em;
        text-transform: uppercase;
    }

    .achievement-info-quantity-column {
        width: 100%;
    }
}
</style>