<template>
  <div :class="brauser ? 'safari' : ''">
    <Preloader />
    <RedirectPopup :data="data?.redirect" />
    <MobileMenu :data="data_k?.header" :language="data?.language" />
    <Header @update="getData" :data="data_k?.header" :languages="data?.language" />
    <!-- <LanguagePopup
      @setLang="getData"
      :data="data?.select_language"
      :languages="data?.language"
    /> -->
    <router-view :data="data"/>
    <Widget :data="data?.widget" />
    <Footer :data="data?.footer" :city="footer_city?.data" />
    <OrderPopup :data="data?.popup" />
    <SuccessPopup :data="data?.popup" />
    <PopupMap :data="data?.popup_map" />
    <RegisterMentor :data="data?.mentors" />
  </div>
</template>


<script>
import axios from "axios";
import { mapMutations, mapState } from "vuex";
import RedirectPopup from "@/components/UI/redirectPopup.vue";
import Header from "@/components/UI/Header.vue";
import Preloader from "@/components/base/Preloader.vue";
import Footer from "@/components/Footer.vue";
import OrderPopup from "@/components/UI/OrderPopup.vue";
import SuccessPopup from "./components/UI/SuccessPopup.vue";
import MobileMenu from "@/components/UI/MobileMenu.vue";
import PopupMap from "./components/UI/PopupMap.vue";
// import LanguagePopup from "@/components/UI/LanguagePopup.vue";
import Widget from "./components/base/Widget.vue";
import RegisterMentor from "./components/UI/RegisterMentor.vue";

export default {
  name: "App",
  components: {
    Preloader,
    Footer,
    Header,
    OrderPopup,
    SuccessPopup,
    RedirectPopup,
    MobileMenu,
    // LanguagePopup,
    PopupMap,
    Widget,
    RegisterMentor,
  },
  data() {
    return {
      data: null,
      data_k: null,
      term: {
        ip: null,
      },
      language: 'ru',
      footer_city: null,
      brauser:   /^((?!chrome|android).)*safari/i.test(navigator.userAgent)
    };
  },
  methods: {
    async getData() {
      this.getFooter(this.currentLang);
      try {
        const result = await axios.get(
          "https://mayaydyryssova.com/v1/methods/site_info?" +
            new URLSearchParams({ lang: this.currentLang }).toString()
        );
        this.data = result.data;
        console.log(result);
        setTimeout(() => {
          this.setLoading(false);
        }, 2000);
      } catch (error) {
        router.push("/404");
        console.clear();
        console.error(error);
      }
    },
    async getDatas() {
      try {
        const result = await axios.get(
          "https://kulyash.com/v1/methods/site_info?" +
            new URLSearchParams({ lang: this.currentLang }).toString()
        );
        this.data_k = result.data;
        setTimeout(() => {
          this.setLoading(false);
        }, 2000);
      } catch (error) {
        router.push("/404");
        console.clear();
        console.error(error);
      }
    },
    async getFooter() {
        try {
          const result = await axios.get(
            "https://mayaydyryssova.com/v1/methods/footer?lang=" + this.currentLang
          );
          this.footer_city = result;
        } catch (error) {
          console.error(error);
        }
    },
    async getPlace() {
      fetch("https://api.ipify.org?format=json")
        .then((x) => x.json())
        .then(({ ip }) => {
          this.term.ip = ip;
        });
    },
    ...mapMutations({
      setLoading: "page/setLoading",
    }),
  },
  computed: {
    ...mapState({
      currentLang: (state) => state.currentLang,
    }),
  },
  async mounted() {
    window.scrollY = 0
    console.log(window.scrollY);
    var isSafari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent);
    this.getPlace();
    const interval = setInterval(async () => {
      if (this.term.ip != null) {
        clearInterval(interval);
        try {
          const result = await axios.get(
            "https://mayaydyryssova.com/v1/methods/locate",
            this.term
          );
          switch (result.data.code) {
            case "KZ":
              this.language = "kz";
              break;

            case "RU":
              this.language = "ru";
              break;

            case "UZ":
              this.language = "uz";
              break;

            case "KG":
              this.language = "kg";
              break;

            case "TJ":
              this.language = "tj";
              break;

            default:
              this.language = "en";
              break;
          }
        } catch (error) {
          console.log(error);
        }
        let current_lang = localStorage.getItem("current_lang");
        if (current_lang) {
          this.$store.commit("setCurrentLang", current_lang);
        } else {
          this.$store.commit("setCurrentLang", this.language);
          localStorage.setItem("current_lang", this.language);
        }
        this.currentLang = this.language
        this.getFooter();
        this.getData();
        this.getDatas();
      }
    }, 50);
    let current_lang = localStorage.getItem("current_lang");
    console.log(current_lang);
  },
  watch: {
    currentLang(newLang, oldLang) {
      localStorage.setItem("current_lang", newLang);
      const current_lang = localStorage.getItem("current_lang");
      this.setLoading(true);
        this.getData();
        this.getDatas();
        if (this.language == "UZ") {
          if (current_lang) {
            if (current_lang == "uz_cyr") {
              this.language = "uz_cyr";
            }
            this.getFooter();
          }
        }
    },
  },
};
</script>

<style>
@font-face {
  font-family: "Montserrat";
  src: url("./assets/fonts/Montserrat/Montserrat-Medium.ttf");
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Montserrat";
  src: url("./assets/fonts/Montserrat/Montserrat-SemiBold.ttf");
  font-weight: 600;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Montserrat";
  src: url("./assets/fonts/Montserrat/Montserrat-Bold.ttf");
  font-weight: 700;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Montserrat";
  src: url("./assets/fonts/Montserrat/Montserrat-Black.ttf");
  font-weight: 800;
  font-style: normal;
  font-display: swap;
}

* {
  box-sizing: border-box;
  margin: 0px;
  padding: 0px;
  text-decoration: none;
}

.safari * {
  filter: saturate(102%);
}

:root {
  --gray: #242424;
  --dark_gray: #121212;
  --light_gray_text: #727272;
  --blue: #0a97a0;
  --light_blue: #31a7af;
  --sky: #c2e8e1;
  --white: #fff;
  --biege: #fdf5e6;
  --light_gray: #0000000a;
  --errors: #c83434;
  --orange: #CAAA63;
  --re-black: #363636;
  --re-light-blue: #0A97A01F;
  --re-light-green: #EFF8F8;
  --re-light-gray-text: #646464;
  --re-light-gray: #0000001F;
  --re-very-light-gray: #969696;
}
body {
  margin: 0;
}

.container {
  max-width: 1240px;
  padding: 0 20px;
  margin: 0 auto;
  width: 100%;
}

h1 {
  color: var(--dark_gray);
  font-family: Montserrat;
  font-size: 64px;
  font-style: normal;
  font-weight: 700;
  line-height: 124%;
}

h2 {
  color: var(--gray);
  font-family: Montserrat;
  font-size: 42px;
  font-style: normal;
  font-weight: 700;
  line-height: 124%;
}

h3 {
  color: var(--gray);
  font-family: Montserrat;
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: 124%;
}

li,
p {
  color: var(--gray);
  font-family: Montserrat;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 164%;
}

ul,
ol {
  padding-left: 20px;
}

.top-span {
  display: block;
  color: var(--blue);
  font-family: Montserrat;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 124%;
  text-transform: uppercase;
  margin-bottom: 12px;
}

.mobile {
  display: none;
}

@media screen and (max-width: 1200px) {
  section {
    margin-bottom: 96px;
  }

  h3 {
    font-size: 16px;
    line-height: 132%; /* 21.12px */
  }

  .mobile {
    display: block;
  }

  .desktop {
    display: none;
  }

  h2 {
    color: var(--gray);
    font-family: Montserrat;
    font-size: 24px;
    font-style: normal;
    font-weight: 700;
    line-height: 124%;
  }

  .top-span {
    color: var(--blue);
    font-family: Montserrat;
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    line-height: 124%;
    margin-bottom: 8px;
  }
  ul,
  ol {
    padding-left: 15px;
  }

  li {
    font-size: 13px;
  }
}
</style>
