<template>
    <section id="friends_map">
        <div class="container">
            <div class="friends_map-title">
                <span class="plan-subtitle" v-html="data_text?.subtitle"></span>
                <h2 v-html="data_text?.title"></h2>
            </div>
            <div class="friends_map-list">
                <div class="friends_map-item" v-for="item in data_text?.cards">
                    <span v-html="item?.title"></span>
                    <p v-html="item?.text"></p>
                </div>
            </div>
            <div class="map-desktop">
                <div class="title-friends_map-container">
                    <h3 v-html="data?.popup_title"></h3>
                    <div class="friends_map-text" v-html="data?.popup_addresses[active_index]?.address_text"></div>
                </div>
                <div class="map-container marketing-plan">
                    <div class="map-btns">
                        <div class="map-btn-item" :class="index == active_index ? 'active' : ''" @click="setActive(item, index)" v-for="item, index in data?.popup_addresses" v-html="item?.address_title"></div>
                    </div>
                    <MapPlan :data="data?.popup_addresses" :active_marker="active_marker"/>
                </div>
            </div>
            <div class="plan-map-btn-mobile">
                <span v-html="data_text?.mobile_text"></span>
                <div class="plan-map-btn" @click="openPopupMup" v-html="data_text?.mobile_btn"></div>
            </div>
        </div>
    </section>
</template>
<script>
import MapPlan from '@/components/UI/MapPlan.vue'
export default {
    props: {
        data: null,
        data_text: null
    },
    data() {
        return {
            active_index: -1,
            active_marker: null
        }
    },
    components: {
        MapPlan
    },
    methods: {
        openPopupMup() {
            this.$store.commit("setPopupMap", true);
            document.querySelector('body').style.overflow = "hidden";
        },
        setActive(item, index) {
            if (index != this.active_index) {
                this.active_index = index
                this.active_marker = item
            }
        },
    }
}
</script>
<style>
    #friends_map {
        padding: 112px 0px;
        background: var(--biege);
    }

    .friends_map-title {
        display: flex;
        flex-direction: column;
        align-items: center;
        margin-bottom: 72px
    }

    .friends_map-title h2 {
        font-size: 48px;
        font-weight: 700;
        line-height: 116%;
        text-align: center;
        max-width: 568px;
    }

    .friends_map-list {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        gap: 20px;
        margin-bottom: 106px;
    }

    .friends_map-item {
        padding: 24px;
        border-radius: 24px;
        background: var(--white);
        box-shadow: 0px 4px 8px 0px rgba(0, 0, 0, 0.05);
        display: flex;
        flex-direction: column;
        gap: 8px;
        min-height: 148px;
    }

    .friends_map-item span {
        font-size: 28px;
        font-weight: 700;
        line-height: 124%;
    }

    .friends_map-item p {
        color: rgba(18, 18, 18, 0.64);
        font-size: 16px;
        font-weight: 500;
        line-height: 136%;
    }

    .plan-map-btn-mobile {
        display: none;
    }

    .map-container.marketing-plan {
        height: 508px;
    }

    .title-friends_map-container h3 {
        font-size: 48px;
        line-height: 112%;
        font-weight: 700;
        max-width: 500px;
    }

    .friends_map-text {
        color: rgba(18, 18, 18, 0.72);
        font-size: 18px;
        font-weight: 500;
        line-height: 156%;
        max-width: 500px;
    }

    .title-friends_map-container {
        display: flex;
        flex-direction: row;
        align-items: flex-end;
        justify-content: space-between;
    }

    @media screen and (max-width: 1200px) {
        .friends_map-list {
            grid-template-columns: 1fr;
            gap: 12px;
            margin-bottom: 38px
        }

        #friends_map {
            padding: 0px;
            background: var(--white);
        }

        #plan #friends_map {
            margin-bottom: 124px
        }

        #friends_map .plan-subtitle {
            margin: 0px;
            text-align: left;
            margin-bottom: 8px;
        }

        .friends_map-title {
            align-items: flex-start;
            margin-bottom: 36px
        }

        .friends_map-title h2 {
            font-size: 26px;
            font-weight: 700;
            line-height: 124%;
            text-align: left;
        }

        .friends_map-item {
            background: var(--biege);
            min-height: 0px;
            box-shadow: none;
        }

        .friends_map-item span {
            text-transform: uppercase;
            font-size: 15px;
            font-weight: 700;
            line-height: 132%;
        }

        .friends_map-item p {
            font-size: 14px;
            font-weight: 500;
            line-height: 136%;
        }

        .plan-map-btn-mobile {
            display: flex;
            flex-direction: column;
            gap: 15px;
            margin-top: 38px;
        }

        .plan-map-btn-mobile span {
            font-size: 18px;
            font-weight: 600;
            line-height: 124%;
            max-width: 253px
        }

        .plan-map-btn {
            font-size: 14px;
            font-weight: 700;
            line-height: 132%;
            text-align: center;
            border-radius: 16px;
            background: var(--blue);
            padding: 16px 24px;
            text-transform: uppercase;
            width: 100%;
            margin: 0px auto;
            color: var(--white);
            transition: 0.5s;
        }

        .plan-map-btn:hover {
            background: rgba(10, 151, 160, 0.84);
        }

        .map-desktop {
            display: none;
        }
    }
</style>