<template>
    <div class="table">
        <div class="table-title" v-if="data?.caption">
            <span>
                {{ data?.caption }}
            </span>
        </div>
        <div class="table-head first-table">
            <div class="table-head-item" :style="index == 0 ? 'width:'+ width_first_head : ''" :class="item.c == '' ? 'none' : ''" v-for="item, index in data?.header" v-html="item.c"></div>
        </div>
        <div class="table-body first-table">
            <div class="table-row" v-for="items, index in data?.body">
                <div class="table-row-item" :style="index == 0 ? 'width:'+ width_first_row : ''" :class="item.c == '' ? 'none' : ''" v-for="item, index in items" v-html="item.c"></div>
            </div>
        </div>
        <div class="mobile-table">
            <div class="table-head">
                <div class="table-head-item" :style="index == 0 ? 'width:'+ width_first_head : ''" :class="index < 3 ? '' : 'none'" v-for="item, index in data?.header" v-html="item.c"></div>
            </div>
            <div class="table-body">
                <div class="table-row" v-for="items in data?.body">
                    <div class="table-row-item" :style="index == 0 ? 'width:'+ width_first_row : ''" :class="index < 3 ? '' : 'none'" v-for="item, index in items" v-html="item.c"></div>
                </div>
            </div>
            <div class="table-head">
                <div class="table-head-item" :style="index == 0 ? 'width:'+ width_first_head : ''" :class="index > 1 ? '' : 'none', index == 2 ? 'none-opacity' : ''"  v-for="item, index in data?.header" v-html="item.c"></div>
            </div>
            <div class="table-body">
                <div class="table-row" v-for="items in data?.body">
                    <div class="table-row-item" :style="index == 0 ? 'width:'+ width_first_row : ''" :class="index > 1 ? '' : 'none', index == 2 ? 'none-opacity' : ''" v-for="item, index in items" v-html="item.c"></div>
                </div>
            </div>
        </div>
        <div class="grid-table">
            <div class="table-row" v-for="item, index in data?.header" :class="index % 2 == 0 ? '' : 'none'" >
                <div class="table-head-item" v-html="item.c"></div>
                <div class="table-row-item" v-html="data?.body[0][index].c"></div>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    props: {
        data: null,
        width_first_head: '100%',
        width_first_row: '100%',
        width_table: '100%'
    }
}
</script>
<style>
    .table {
        display: flex;
        flex-direction: column;
        gap: 4px;
    }

    .table-title {
        font-size: 12px;
        font-weight: 800;
        line-height: 132%;
        padding: 8px 12px;
        border-radius: 4px;
        background: var(--sky);
    }

    .table-head {
        display: flex;
        flex-direction: row;
        gap: 4px;
    }

    .table-head .table-head-item {
        width: 100%;
        font-size: 12px;
        font-weight: 800;
        line-height: 132%;
        background: var(--sky);
        padding: 8px 12px;
        border-radius: 4px;
    }

    .table-head-item.none {
        opacity: 0;
    }

    .table-body {
        display: flex;
        flex-direction: column;
        gap: 4px;
    }

    .table-row {
        display: flex;
        flex-direction: row;
        gap: 4px;
        width: 100%;
    }

    .table-row-item {
        width: 100%;
        padding: 8px 12px;
        border-radius: 4px;
        background: var(--biege);
        font-size: 12px;
        font-weight: 800;
        line-height: 132%;
    }

    .table-row-item:first-of-type {
        background: var(--orange);
        font-size: 12px;
        font-weight: 600;
        line-height: 132%;
    }

    .mobile-table {
        display: none;
    }

    .grid-table {
        display: none;
    }
</style>