<template>
  <section :class="this.$store.state.currentLang" id="about" v-if="data != null">
    <div class="container">
      <h2 class="invites-title" v-html="data?.title"></h2>
      <div class="invites-container">
        <div
          class="invites-item"
          v-for="(item, index) in data?.items"
          :class="(index + 1) % 2 == 0 ? 'reverse' : ''"
        >
          <div class="invites-item-text">
            <div v-html="item.text"></div>
            <Button v-if="item.link == ''"
              @click="popupOpen"
              class="desktop"
              :btn_class="(index + 1) % 2 == 0 ? 'blue-stroke' : 'blue'"
              :value="item.btn_text"
            />
            <Button v-else
              @click="openRedirect"
              class="desktop"
              :btn_class="(index + 1) % 2 == 0 ? 'blue-stroke' : 'blue'"
              :value="item.btn_text"
            />
          </div>
          <img :src="item.img" alt="" />
          <Button
            @click="popupOpen"
            :link="item.link"
            class="mobile"
            :btn_class="(index + 1) % 2 == 0 ? 'blue-stroke' : 'blue'"
            :value="item.btn_text"
          />
        </div>
      </div>
    </div>
  </section>
</template>
<script>
import Button from "../UI/Button.vue";

export default {
  components: {
    Button,
  },
  props: {
    data: null,
  },
  methods: {
    popupOpen() {
      this.$store.commit('currentPlace', 'Форма “Приглашаем амбициозных и уверенных”');
      this.$store.commit("openPopup", true);
    },
    openRedirect() {
      this.$store.commit("openRedirect", true);
      this.$store.commit("setPopupLink", this.data?.items[1].link);
    },
  },
};
</script>
<style>
.mobile {
  display: none;
}

.invites-title,
.invites-title > * {
  color: var(--blue);
  font-family: Montserrat;
  font-size: 42px;
  font-style: normal;
  font-weight: 700;
  line-height: 116%;
  margin-bottom: 48px;
}

/* #about .invites-title {
  font-size: 32px;
  max-width: 600px;
} */

/* #about .invites-title br.desktop {
  display: none;
} */

.invites-container {
  display: flex;
  flex-direction: column;
  gap: 124px;
}

.invites-item {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 30px;
  justify-content: space-between;
}

.invites-item-text {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.invites-item-text h3 {
  color: var(--gray);
  font-family: Montserrat;
  font-size: 32px;
  font-style: normal;
  font-weight: 700;
  line-height: 132%;
  margin-bottom: 20px;
}

.invites-item-text p {
  color: var(--dark_gray);
  font-family: Montserrat;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 164%;
}

.uz .invites-item-text p {
  color: #121212;
  font-family: Montserrat;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 164%; 
}

.invites-item-text .blue {
  margin-top: 16px;
}

.invites-item img {
  width: 100%;
  height: auto;
  max-width: 508px;
  /* border-radius: 64px; */
}

.invites-item.reverse {
  flex-direction: row-reverse;
  gap: 65px;
}

@media screen and (max-width: 1240px) {
    .invites-item-text p {
      font-size: 15px;
    }
}

@media screen and (max-width: 1200px) {
  .mobile {
    display: block;
  }

  .button.desktop {
    display: none;
  }

  .invites-title {
    color: var(--blue);
    color: #0a97a0;
    font-family: Montserrat;
    font-size: 24px;
    font-style: normal;
    font-weight: 700;
    line-height: 124%;
    max-width: 445px;
  }

  .invites-item,
  .invites-item.reverse {
    flex-direction: column;
    gap: 32px;
  }

  .invites-item .button {
    margin-top: 10px;
  }

  .uz .invites-item .button,
  .uz_cyr .invites-item .button {
    font-size: 15px;
  }

  .invites-item-text h3 {
    color: var(--gray);
    font-family: Montserrat;
    font-size: 18px;
    font-style: normal;
    font-weight: 700;
    line-height: 132%;
    margin-bottom: 12px;
  }

  .invites-item-text p {
    color: var(--dark_gray);
    font-family: Montserrat;
    font-size: 13px;
    font-style: normal;
    font-weight: 500;
    line-height: 164%;
  }

  .invites-container {
    gap: 96px;
  }

  .invites-item-text {
    gap: 12px;
  }

  .uz .invites-item-text p {
    color: #121212;
    font-family: Montserrat;
    font-size: 13px;
    font-style: normal;
    font-weight: 500;
    line-height: 164%;
  }
}
</style>
