<template>
  <section :class="this.$store.state.currentLang" class="form" v-if="data != null">
    <div class="container section-form__wrapper">
      <div class="section-form-header">
        <div class="section-form-name">
          <img :src="require('@/assets/img/taovita.svg')" alt="" />
        </div>
        <h2 class="section-form-title" v-html="data?.title"></h2>
      </div>
      <form class="section-form">
        <div class="section-form-input">
          <input
            type="text"
            name=""
            id=""
            :placeholder="data?.input_text"
            v-model="name"
            :class="error_name == '' ? '' : 'inp-error'"
          />
          <span
            :class="
              error_name == '' ? 'section-form-success' : 'section-form-error'
            "
            >{{ error_name }}</span
          >
        </div>
        <div class="section-form-input">
          <input
            type="text"
            name=""
            id=""
            :placeholder="data?.input_phone"
            v-model="phone"
            :class="error_phone == '' ? '' : 'inp-error'"
          />
          <span
            :class="
              error_phone == '' ? 'section-form-success' : 'section-form-error'
            "
            >{{ error_phone }}</span
          >
        </div>
        <Button
          @click="createOrder"
          class="section-form-btn"
          :btn_class="'blue'"
          :value="data?.btn_text"
        />
        <div class="section-form-policy" v-html="data?.pp"></div>
      </form>
    </div>
  </section>
</template>
<script>
import { mapMutations, mapState } from "vuex";
import { replaceNumberForPaste, replaceToLatters } from "@/assets/js/helper.js";
import { sendComagic } from '@/assets/js/comagic.js'
import orderApi from "@/assets/js/api/order.js";
import Button from "../UI/Button.vue";
import router from "@/router";
export default {
  components: { Button },
  props: {
    data: null,
  },
  data() {
    return {
      name: "",
      phone: "",
      error_name: "",
      error_phone: "",
      is_validated: false,
      error_order: "",
    };
  },
  methods: {
    async createOrder() {
      this.error_order = "";

      if (this.validate()) {
        let place = ''
        if (router.currentRoute.value.path == '/marketing-plan') {
          place = "Заявка маркетинг-план";
        } else {
          place = "форма “Форма в конце перед Подвалом”";
        }
        sendComagic(this.name, this.phone, place);
        const result = await orderApi.create(this.name, this.phone);

        if (result.success) {
          this.name = "";
          this.phone = "";
          this.error_name = "";
          this.error_phone = "";
          this.is_validated = false;
          this.$store.commit("successPopup", true);
        } else {
          this.error_name = "";
          this.error_phone = "";
          this.is_validated = false;
          this.error_order = result.message;
        }
      }
    },
    validate() {
      let  req_fild = ''
      let phone_error = ''
      if (this.$store.state.currentLang == 'ru') {
        req_fild = 'Обязательное поле'
        phone_error = 'Введите существующий номер'
      }

      if (this.$store.state.currentLang == 'kz') {
        req_fild = 'Міндетті өріс'
        phone_error = 'Бар нөмірді енгізіңіз'
      }

      if (this.$store.state.currentLang == 'uz') {
        req_fild = 'Majburiy maydon'
        phone_error = 'Mavjud raqamni kiriting'
      }
      
      if (this.$store.state.currentLang == 'uz_cyr') {
        req_fild = 'Керакли майдон'
        phone_error = 'Мавжуд рақамни киритинг'
      }

      if (this.$store.state.currentLang == 'kg') {
        req_fild = 'Милдеттүү талаа'
        phone_error = 'Учурдагы Номерди киргизиңиз'
      }

      if (this.$store.state.currentLang == 'en') {
        req_fild = 'Required field'
        phone_error = 'Enter an existing numbe'
      }

      if (this.$store.state.currentLang == 'tj') {
        req_fild = 'Майдони ҳатмӣ'
        phone_error = 'Раками дохил кунед'
      }

      this.error_name = "";
      this.error_phone = "";
      this.error_email = "";

      if (this.name == "") {
        this.error_name = req_fild;
      }

      if (this.phone == "") {
        this.error_phone = req_fild;
      }

      this.is_validated = true;

      return this.error_name == "" && this.error_phone == "";
    },
  },
    computed: {
      ...mapState({
          currentLang: state => state.currentLang,
      })
  },
  watch: {
    currentLang () {
      this.error_name = "";
      this.error_phone = "";
    },
    name(new_value) {
        this.name = replaceToLatters(new_value);
        if (this.is_validated) {
          this.validate();
        }
    },
    phone(new_value) {
        this.phone = new_value.replace(/[^0-9]/g, '');
        if (this.is_validated) {
          this.validate();
        }
    },
  },
};
</script>
<style>
.form {
  background-color: var(--biege);
  border-radius: 64px 64px 0 0;
  padding: 98px 0 164px;
  margin-bottom: 0;
}
.container.section-form__wrapper {
  display: flex;
  flex-direction: column;
  gap: 60px;
  max-width: 775px;
}
.section-form-header {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 24px;
}
.section-form-name {
}
.section-form-title {
  text-align: center;
}
.section-form {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 20px 8px;
}
.section-form > * {
  width: 100%;
}
.section-form-block {
  display: flex;
  gap: 8px;
}
.section-form-input {
  position: relative;
}
.section-form-input input {
  padding: 16px;
  border: 1px solid rgba(0, 0, 0, 0.12);
  outline: none;
  background-color: var(--white);
  color: var(--dark_gray);
  font-family: Montserrat;
  font-size: 14px;
  font-weight: 600;
  line-height: 132%; /* 18.48px */
  letter-spacing: 0.28px;
  text-transform: uppercase;
  border-radius: 16px;
  width: 100%;
}
.section-form-input input.inp-error {
  border: 1px solid var(--errors);
}
.section-form-input input::placeholder {
  color: rgba(18, 18, 18, 0.48);
}
.section-form-input input:focus,
.section-form-input input:focus-visible,
.section-form-input input:focus-within,
.section-form-input input:not(:placeholder-shown) {
  border: 1px solid var(--blue);
}
.section-form-btn {
  padding: 16px 24px;
}
.section-form-policy {
  padding-left: 15px;
}
.section-form-policy p {
  color: rgba(18, 18, 18, 0.64);
  font-family: Montserrat;
  font-size: 10px;
  font-weight: 400;
  line-height: 132%; /* 13.2px */
}
.section-form-policy p a {
  color: rgba(18, 18, 18, 0.64);
  text-decoration: underline;
  font-family: Montserrat;
  font-size: 10px;
  font-weight: 400;
  line-height: 132%; /* 13.2px */
}

.section-form-error {
  margin-top: 4px;
  display: block;
  font-family: Montserrat;
  font-size: 11px;
  font-weight: 500;
  line-height: 132%; /* 14.52px */
  color: var(--errors);
}

@media screen and (max-width: 1200px) {
  .form {
    padding: 53px 0 89px;
    border-radius: 32px 32px 0 0;
  }
  .section-form__wrapper {
    gap: 36px;
  }
  .section-form {
    grid-template-columns: 1fr;
    gap: 8px;
  }
  .section-form-btn {
    margin-top: 16px;
  }
  .section-form-policy {
    padding: 0;
  }
  .section-form-policy p a {
    text-decoration: none;
  }
  .section-form-header {
    gap: 16px;
  }
  .section-form-title {
    font-size: 28px;
  }

  .uz_cyr .section-form-title {
    color: #121212;
    text-align: center;
    font-family: Montserrat;
    font-size: 24px;
    font-style: normal;
    font-weight: 700;
    line-height: 112%;
  }

  .section-form-btn {
    font-size: 14px;
    margin-bottom: 4px;
  }
}
</style>
